@media only screen and (min-width : 1080px) {
  .head{
    max-width: 25%;
    font-size: 1.7em;
  }
}

.head{
  position: relative;
  top:5px;
  width: 300px;
  text-align: center;
  font-size: 1.5em;
  background-color: bisque;
  margin: auto;
  border-radius: 10px;
  padding: 5px 5px;
}

#totalValue,
#stopLossValue,
#futuresProducts,
#takeProfitPrice,
#stopLossPrice {
width: 60px;
}

