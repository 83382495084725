:root {
  --color-blue: #0AB5CD;
  --color-light-blue: #5ECEDB;
  --bg-color: #DEF2D9;
  --bg-dot-color: #FFF;
  --shadow-3: rgba(0, 0, 0, 0.03);
  --shadow-5: rgba(0, 0, 0, 0.05);
  --shadow-6: rgba(0, 0, 0, 0.06);
  --shadow-8: rgba(0, 0, 0, 0.08);
  --shadow-9: rgba(0, 0, 0, 0.09);
  --shadow-10: rgba(0, 0, 0, 0.10);
  --shadow-15: rgba(0, 0, 0, 0.16);
  --shadow-16: rgba(0, 0, 0, 0.16);
  --shadow-20: rgba(0, 0, 0, 0.20);
  /* --center-bg-color: #FFF; */
  --wave-1: rgba(255, 255, 255, 0);
  --wave-2: rgba(255, 255, 255, 0.2);
  --wave-3: rgba(255, 255, 255, 0.4);
  --wave-4: rgba(255, 255, 255, 0.6);
  --nook-phone-bg-color: #F5F8FF;
  --nook-phone-text-color: #686868;
  --dialog-bg-color: #FFFAE5;
  --dialog-text-color: #837865;
  --dialog-name-bg-color: #FF9A40;
  --dialog-name-text-color: #BA3B1F;
  --chart-fill-color: var(--bg-color);
  --chart-line-color: rgba(0, 0, 0, 0.1);
  --chart-point-color: rgba(0, 0, 0, 0.1);
  --select-text-color: var(--dialog-text-color);
  --select-border-color: var(--bg-color);
  --select-bg-color-hover: #EBFEFD;
  --italic-color: #AAA;
  --form-h6-text-color: #845E44;
  --radio-hover-bg-color: var(--nook-phone-bg-color);
  --radio-checked-text-color: #FFF;
  --input-bg-color: #F3F3F3;
  --input-focus-bg-color: white;
  /* --input-focus-text-color: var(--color-blue); */
  /* --button-text-color: var(--nook-phone-text-color); */
  /* --button-reset-text-color: #E45B5B; */
  --table-range0: hsl(140, 80%, 85%);
  /* --table-range1: hsl(90, 80%, 85%); */
  /* --table-range2: hsl(60, 80%, 85%); */
  /* --table-range3: hsl(30, 80%, 85%); */
  /* --table-range4: hsl(0, 80%, 85%); */
}

html {
  background: var(--bg-color);
  background-image:
    radial-gradient(var(--bg-dot-color) 20%, transparent 0),
    radial-gradient(var(--bg-dot-color) 20%, transparent 0);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  overflow-x: hidden;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (mix-width : 1080px) {
  .table{
      width: 100%;
  }
}

@media only screen and (mix-width: 1080px) and (min-resolution: 250dpi ) {
  div {
    font-size: 18px;
  }
}