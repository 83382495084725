.container {
  display: flex;
}

#container1 {
position: relative;
top: 20px;
max-width: 500px;
margin: auto;
justify-content: space-between;
}

#container2 {
  position: relative;
  top:100px;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 0 10px;
  z-index: 1;
  align-items: center;
  flex-wrap: wrap;
}

#futureProducts {
  width: 100%;
}

.container2Item{
  margin: 5px;

}

.container2Item2{
  margin: 5px;
  font-weight: 600;
  font-size: 25px;
}
.button {
    background-color: rgb(255, 166, 0);
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px 8px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}

@media only screen and (max-width : 530px) {
  #container1 {
    position: relative;
    top: 20px;
    width: 45%;
    margin: auto;
    justify-content: space-between;
    }

  #container2 {
    position: relative;
    top:120px;
    width: 90%;
    text-align: center;
    justify-content: center;
    padding: 0 10px;
    z-index: 1;
    align-items: center;
    flex-wrap: wrap;
  }
}

